import { baseApi } from 'shared/api/baseApi';
import { FAVOURITE_VAULTS_TAG, VAULT_TAG, VAULT_TAGS_TAG, VAULTS_LIST_TAG } from 'shared/api/tags';
import { TCreateVaultForm, TVaultsDTO, TVaultTagsDTO } from './type';
import qs from 'qs';
import { TVault } from '../model/types';
import { VAULTS_PER_PAGE, TAGS_AMOUNT } from '../model/constants';
import { deleteVault, setVaultsList, updateVaultIsFavourite, updateVaultsList } from '../model/slice';

export const vaultsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getVaults: build.query<TVaultsDTO, { tag?: string; search?: string; page: number; vaultsPerPage?: number }>({
			query: ({ tag, search, page, vaultsPerPage }) => {
				let query: any = {
					name: {
						contains: search
					}
				};
				if (tag) {
					query = {
						...query,
						tags: {
							equals: tag
						}
					};
				}
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: query,
						limit: vaultsPerPage ?? VAULTS_PER_PAGE,
						page
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `vaults${stringifiedQuery}`
				};
			},
			onQueryStarted: async (payload, { dispatch, getState, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					if (payload.page !== 1) {
						dispatch(updateVaultsList(data.docs));
					} else {
						dispatch(setVaultsList(data.docs));
					}
				} catch (err) {
					console.error(err);
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			merge: (currentCache, newItems, { arg }) => {
				if (arg.page !== 1) {
					return {
						...currentCache,
						...newItems,
						docs: [...currentCache.docs, ...newItems.docs]
					};
					currentCache.docs.push(...newItems.docs);
				}
				return newItems;
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: [VAULTS_LIST_TAG]
		}),
		getVault: build.query<TVault, string>({
			query: (id) => ({
				url: `vaults/${id}`
			}),
			providesTags: [VAULT_TAG]
		}),
		getVaultsByIds: build.query<TVaultsDTO, string[]>({
			query: (ids) => {
				const stringifiedQuery = qs.stringify(
					{
						where: {
							id: {
								in: ids
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `vaults${stringifiedQuery}`
				};
			}
		}),
		getFavouriteVaults: build.query<TVault[], void>({
			query: () => ({
				url: `users/me`
			}),
			transformResponse: (response: { user: { favoriteVaults?: TVault[] } }) => {
				return response.user.favoriteVaults ?? [];
			},
			providesTags: [FAVOURITE_VAULTS_TAG]
		}),
		updateIsFavouriteVault: build.mutation<void, { id: string; isFavourite: boolean }>({
			query: ({ id, isFavourite }) => {
				const method = isFavourite ? 'POST' : 'DELETE';
				return {
					url: `users/me/favorites/vaults/${id}`,
					method: method
				};
			},
			async onQueryStarted({ id, isFavourite }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(updateVaultIsFavourite({ id, isFavourite }));
				} catch (err) {
					console.error(err);
				}
			},
			invalidatesTags: [FAVOURITE_VAULTS_TAG]
		}),
		getVaultTags: build.query<TVaultTagsDTO, void>({
			query: () => {
				const stringifiedQuery = qs.stringify(
					{
						limit: TAGS_AMOUNT,
						page: 1
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `vaults_tags${stringifiedQuery}`
				};
			},
			providesTags: [VAULT_TAGS_TAG]
		}),
		createVault: build.mutation<TVault, TCreateVaultForm>({
			query: (data) => {
				return {
					url: 'vaults?depth=0',
					method: 'POST',
					data
				};
			},
			transformResponse: (response: { doc: TVault; message: string }) => {
				return response.doc;
			}
		}),
		editVault: build.mutation<TVault, TCreateVaultForm>({
			query: (data) => {
				return {
					url: `vaults/${data.id}`,
					method: 'PATCH',
					data
				};
			},
			transformResponse: (response: { doc: TVault; message: string }) => {
				return response.doc;
			},
			invalidatesTags: [VAULTS_LIST_TAG, FAVOURITE_VAULTS_TAG]
		}),
		deleteVault: build.mutation<TVaultsDTO, string>({
			query: (id) => {
				const stringifiedQuery = qs.stringify(
					{
						depth: 0,
						where: {
							id: {
								equals: id
							}
						}
					},
					{ addQueryPrefix: true }
				);
				return {
					url: `vaults${stringifiedQuery}`,
					method: 'DELETE'
				};
			},
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(deleteVault({ id }));
				} catch (err) {
					console.error(err);
				}
			},
			invalidatesTags: [FAVOURITE_VAULTS_TAG]
		})
	})
});

export const {
	useGetVaultsQuery,
	useGetVaultsByIdsQuery,
	useGetFavouriteVaultsQuery,
	useLazyGetFavouriteVaultsQuery,
	useLazyGetVaultsQuery,
	useUpdateIsFavouriteVaultMutation,
	useGetVaultTagsQuery,
	useCreateVaultMutation,
	useEditVaultMutation,
	useDeleteVaultMutation
} = vaultsApi;
